














































import { Settings, SettingsFacilities } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import VueRouter from "vue-router";
import { Facility } from "@/models";

@Component({
  mixins: [eventHandler]
})
export default class ModGroup extends Vue {
  @Prop() item!: Facility;

  btnLoading!: Function;
  btnRestore!: Function;
  confirm!: Function;
  $notify: any;

  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this item?";
  }

  remove(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText).then(
      (value: boolean) => {
        if (value) {
          SettingsFacilities.destroy(id)
            .then((response: any) => {
              this.$emit("modifier:update");
              this.$notify({
                title: "DATA DELETED",
                verticalAlign: "bottom",
                horizontalAlign: "left",
                message: "Modifier successfully deleted",
                type: "success",
                icon: "fas fa-trash"
              });
            })
            .catch((err: any) => {
              this.$notify({
                title: "SYSTEM ERROR!",
                verticalAlign: "bottom",
                horizontalAlign: "left",
                message: "Something went wrong, please try again!",
                type: "danger",
                icon: "fas fa-bomb"
              });
            });
        }
      }
    );
  }
}
